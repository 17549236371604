<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30" class="link-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
      <rect x="12" y="12" width="6" height="6" rx="3"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 15C3 8.37258 8.37258 3 15 3C21.6274 3 27 8.37258 27 15C27 21.6274 21.6274 27 15 27C8.37258 27 3 21.6274 3 15ZM9.5 15C9.5 11.9624 11.9624 9.5 15 9.5C18.0376 9.5 20.5 11.9624 20.5 15C20.5 18.0376 18.0376 20.5 15 20.5C11.9624 20.5 9.5 18.0376 9.5 15ZM21 7.5C20.1716 7.5 19.5 8.17157 19.5 9C19.5 9.82843 20.1716 10.5 21 10.5C21.8284 10.5 22.5 9.82843 22.5 9C22.5 8.17157 21.8284 7.5 21 7.5Z"/>
    </svg>
  </template>